import React from 'react';

import about from '../../Assets/rcscoaching2.jpeg';



const Aboutus = () => {
  return (
    <>

<div className='banner'>
        <h1>About us</h1>
        
       
    </div>
    <div className='a-all'>
        <div className='a-image'>
            <div className='g-container'></div>
            <img src={about} />

        </div>

        <div className='a-text'>
             <h1>About Us</h1>
             <p>
             Welcome to Academy Achievers Tuition Classes, where your success is our mission. Established with the vision of nurturing young minds and guiding them towards academic excellence, we specialize in providing comprehensive coaching for JEE, NEET, and a wide array of other competitive exams.
<br/>
At Achievers, we believe that every student has the potential to achieve greatness. Our experienced faculty, proven teaching methodologies, and state-of-the-art learning resources are designed to empower students with the knowledge and skills they need to excel.
<br/>
Our programs are meticulously structured to ensure a deep understanding of concepts, regular practice through mock tests, and personalized attention to address individual learning needs. With a focus on both theoretical and practical aspects, we prepare students not just to crack exams but to build a strong foundation for their future academic and professional pursuits.
<br/>
Join us at Achievers Coaching Classes and embark on a journey of academic success and personal growth. Together, let's achieve excellence!
                

             </p>
           
             
            
        </div>
    </div>
    </>
  )
}

export default Aboutus