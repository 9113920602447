import logo from './logo.svg';
import './App.css';
import Home from './Components/Home';
import Header from './Components/header/Header';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Footer2 from './Components/footer2/Footer2';


import Contact2 from './pages/contact2/Contact2';


import Project2 from './pages/project2/Project2';
import Footer3 from './Components/Footer3';

import Achieversjunior from './Components/courses/Achieversjunior';
import Achieversmedical from './Components/courses/Achieversmedical';
import Achieversengineering from './Components/courses/Achieversengineering';
import Achieverspuc from './Components/courses/Achieverspuc';
import Achieverssenior from './Components/courses/Achieverssenior';
import Achieverscommerce from './Components/courses/Achieverscommerce';
import Aboutus from './pages/aboutus/Aboutus';

function App() {
  return (
    <div >
<BrowserRouter>
     <Header/>
        <Routes>
        <Route path='/' element={<Home />} />
        
         <Route path='/Projects' element={<Project2 />} />
        
        <Route path='/contact' element={<Contact2 />} />
        <Route path='/about' element={<Aboutus/>} />
        <Route path='/Achieversjunior' element={<Achieversjunior/>} />
        <Route path='/Achieverssenior' element={<Achieverssenior/>} />
        <Route path='/Achieverscommerce' element={<Achieverscommerce/>} />
        <Route path='/Achieverspuc' element={<Achieverspuc/>} />
        <Route path='/Achieversengineering' element={<Achieversengineering/>} />
        <Route path='/Achieversmedical' element={<Achieversmedical/>} />
     

    

        </Routes>
       
     
     
     </BrowserRouter>
     <Footer3/>
    <Footer2/>
    </div>
  );
}

export default App;
