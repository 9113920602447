import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import './Achieversjunior.css';


const Achieversengineering = () => {
  return (
    <>

<div className='banner'>
        <h1>Achievers Engineering</h1>
        
       
    </div>


    <div className=' a-all'>
      

       <div className='a-text1'>
              <h5>Achievers Academy offers a specialized course named Achievers Engineering, designed for students preparing for the IIT JEE and other engineering entrance exams. This course focuses on building a strong foundation in Physics, Chemistry, and Mathematics, ensuring students are well-prepared for the rigorous demands of these competitive exams. Our experienced instructors use advanced teaching techniques and problem-solving strategies to enhance understanding and application of concepts. The curriculum is comprehensive and challenging, aimed at developing critical thinking and analytical skills. Personalized guidance and regular practice tests help students identify their strengths and areas for improvement. Achievers Engineering also emphasizes time management and effective study habits, crucial for success in entrance exams. Our goal is to provide students with the knowledge, skills, and confidence needed to excel in the IIT JEE and secure admission to top engineering colleges.</h5>
              
              
       </div>
       

       <div className='a-image1'>
         <img src='https://akm-img-a-in.tosshub.com/indiatoday/images/story/202008/tea_0.jpeg?size=690:388' />
       </div>


    </div>


    <div className=' a-all'>

       <div className='a-image'>
         <img src='https://ssbcrackexams.com/wp-content/uploads/2024/01/coaching-classes.jpg' />
       </div>

       <div className='a-text'>
              <h1 style={{fontFamily:'serif'}}>Course Highlights</h1>
              <h4><FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''} Intensive coaching tailored for IIT JEE and engineering entrance exams.</h4>
              <h4> <FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''}   Detailed study of Physics, Chemistry, and Mathematics aligned with exam requirements.</h4>
              <h4><FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''} Experienced teachers specializing in engineering entrance exam coaching.</h4>
              <h4><FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''} Regular mock tests and assessments to track progress and improve performance.</h4>
              <h4><FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''} Individual attention, doubt-solving sessions, and mentorship for student success.</h4>
              
              <br/>
              
       </div>
       
    </div>
      <br/>

    <h1 style={{textAlign:'center',fontFamily:'serif'}}>Get In Touch With us</h1>
      <br/>
    <div class="form-container">
          <form action="your-google-form-link" method="post">
            <div className="c-name">
            
            <input  style={{fontSize:'20px'}} type="text" id="name" name="name" placeholder="Name"required />
            
           <div style={{padding:'15px'}}></div>
            
            <input style={{fontSize:'20px'}} type="email" id="gmail" name="gmail" placeholder="Gmail" required />
            

            </div>
            <br/>
            <br/>
             <div className="c-subject">
            
            <input style={{fontSize:'20px'}} type="text" id="subject" name="subject" placeholder="Subject"required />
            </div>

            <br/><br/>
            <div className="c-message">
            <textarea style={{fontSize:'20px'}} id="message" name="message" rows="4" cols="50" placeholder="Message" required></textarea>
            </div>
             <br/>
             <div className="c-submit">
            <button type="submit"  style={{backgroundColor:'rgb(34, 34, 86)',color:'white'}} value="Submit" >Send</button>
            </div>
          </form>
        </div>

    </>
  )
}

export default Achieversengineering