import React from 'react';
import './Footer1.css';

import social1 from '../../Assets/facebook.png';

import social4 from '../../Assets/instagram.png';


import { useRef } from 'react';


import emailjs from '@emailjs/browser';

const Footer1 = () => {

   const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_ymb0zin', 'template_mlfxyxf', form.current, 'WQ1EiyuosIfZS_a54')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };


  return (
    <>
  
    <div className='asa' >
    <div className='f1-all'>
     <div className='f-left'>
         
         
         <br/>
<br/>
<br/>




         <h4 style={{color:'navy' ,fontSize:'25px'}}>Address:</h4>
         <h6>#18/19,1st & 2nd Floor,Adityanagar,</h6>
         <h6>  vidyaranyapura,Bangalore,</h6>
         <h6>560097,karnataka,India</h6>
         <br/>
         <h4 style={{color:'navy'}}>Office Timimg:</h4>
         <h6>Open ⋅ Closes 6 pm</h6>
         <br/>
         <h4 style={{color:'navy'}}>Phone no. :</h4>
         <h6> +91 7022277630</h6>
         <br/>
         <h4 style={{color:'navy'}}>Mail Id:</h4>
         <h6> Academyachievers360@gmail.com</h6>
         <br/>
         <br/>
        <div className='social-all'>
        <a>
            <div classname='social'>
                 <img style={{width:'50px',paddingLeft:'8px'}}  src={social1}/>
            </div>
         </a>
        
         <a>
            <div classname='social'>
            <img style={{width:'50px',paddingLeft:'8px'}}  src={social4}/>
            </div>
         </a>

        </div>
        <br/>
         
     </div>

     <div className='f-right'>
            <div className='co111-all'>
     <div className='co11-all'>
        
        <form ref={form} onSubmit={sendEmail}>
        <h1 style={{color:'white',textAlign:'center'}}>Get In Touch With Us</h1>
             <div className="form-group">
               <label style={{color:'white'}}>Name</label>
               <input className="form-control  " type="text" name="user_name" />
             </div>
             <div style={{padding:"5px"}}></div>
             <div className="form-group">
               <label style={{color:'white'}}>Email</label>
               <input className="form-control form-control-sm" type="email" name="user_email" />
             </div>
             <div style={{padding:"5px"}}></div>
             <div className="form-group">
               <label style={{color:'white'}}>Message</label>
               <textarea className="form-control form-control-sm" name="message" />
             </div>
             <div style={{padding:"10px"}}></div>
             <div className="col-12 pt-3" >
               <input className="btn btn-primary" style={{width:"200px"}} type="submit" value="Send" />
             </div>
           </form>
        </div>
        </div>
      </div>
           
      </div>
      </div>
   

      <div className='map'>
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.2313142878293!2d77.5526347!3d13.0845214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4e2d2dbea4bf7731%3A0x85ea18a2b5a18061!2sAcademy%20Achiever&#39;s!5e0!3m2!1sen!2sin!4v1718492282712!5m2!1sen!2sin" ></iframe>
</div>

    </>
  )
}

export default Footer1