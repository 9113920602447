import React from 'react';
import './footer3.css';

const Footer3 = () => {
  return (
    <footer className="footer">
      <div className="footer-section">
        <h3 style={{color:'white'}}>About Us</h3>
        <p style={{color:'white'}}>Academy Achievers Coaching Classes provides top-notch coaching for JEE, NEET, and other competitive exams, helping students achieve academic excellence and secure a bright future.</p>
      </div>
      <div className="footer-section">
        <h3 style={{color:'white'}}>Useful Links</h3>
        <ul>
          <li><a style={{color:'white'}} href="/">Home</a></li>
          <li><a style={{color:'white'}} href="/Achieversjunior">Our Courses</a></li>
          <li><a style={{color:'white'}} href="/Contact">Contact</a></li>
          <li><a style={{color:'white'}} href="/About">About Us</a></li>
        </ul>
      </div>
      <div className="footer-section">
        <h3 style={{color:'white'}}>Services</h3>
        <ul>
        <li><a style={{color:'white'}} href="/AchieversJunior">Achievers junior</a></li>
          <li><a style={{color:'white'}} href="/Achieverssenior">Achievers Senior</a></li>
          <li><a style={{color:'white'}} href="/AchieversEngineering">JEE Coaching</a></li>
          <li><a style={{color:'white'}} href="/Achieversmedical">NEET Coaching</a></li>
        
        </ul>
      </div>
    </footer>
  );
};

export default Footer3;
