import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import './Achieversjunior.css';


const Achieverspuc = () => {
  return (
    <>

<div className='banner'>
        <h1>Achievers PUC</h1>
        
       
    </div>

    <div className=' a-all'>
      

       <div className='a-text1'>
              <h5>Achievers Academy offers a comprehensive course named Achievers PUC, specifically designed for pre-university students pursuing Science and Commerce streams. This course aims to provide an in-depth understanding of key subjects, preparing students for higher education and competitive exams. Our expert educators use innovative teaching methods to ensure thorough comprehension and retention of concepts. The curriculum is meticulously crafted to cover all essential topics, promoting critical thinking and analytical skills. Personalized attention is given to each student to address their individual learning needs and goals. Regular assessments and feedback help track progress and identify areas for improvement. Alongside academics, Achievers PUC emphasizes the development of time management and exam preparation skills. Our goal is to equip students with the knowledge and confidence required to excel in their academic pursuits and future careers.</h5>
              
              
       </div>
       

       <div className='a-image1'>
         <img src='https://akm-img-a-in.tosshub.com/indiatoday/images/story/202008/tea_0.jpeg?size=690:388' />
       </div>


    </div>

    <div className=' a-all'>

       <div className='a-image'>
         <img src='https://ssbcrackexams.com/wp-content/uploads/2024/01/coaching-classes.jpg' />
       </div>

       <div className='a-text'>
              <h1 style={{fontFamily:'serif'}}>Course Highlights</h1>
              <h4><FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''}  Tailored coaching for Science and Commerce streams in pre-university.</h4>
              <h4> <FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''} Thorough coverage of relevant subjects, ensuring readiness for higher education.</h4>
              <h4><FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''}Skilled instructors with expertise in guiding pre-university students.</h4>
              <h4><FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''} Regular practice tests and assessments to gauge progress and readiness.</h4>
              <h4><FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''} Individualized attention and guidance to address student's academic needs and goals.</h4>
              <br/>
              
       </div>
       
    </div>
      <br/>

    <h1 style={{textAlign:'center',fontFamily:'serif'}}>Get In Touch With us</h1>
      <br/>
    <div class="form-container">
          <form action="your-google-form-link" method="post">
            <div className="c-name">
            
            <input  style={{fontSize:'20px'}} type="text" id="name" name="name" placeholder="Name"required />
            
           <div style={{padding:'15px'}}></div>
            
            <input style={{fontSize:'20px'}} type="email" id="gmail" name="gmail" placeholder="Gmail" required />
            

            </div>
            <br/>
            <br/>
             <div className="c-subject">
            
            <input style={{fontSize:'20px'}} type="text" id="subject" name="subject" placeholder="Subject"required />
            </div>

            <br/><br/>
            <div className="c-message">
            <textarea style={{fontSize:'20px'}} id="message" name="message" rows="4" cols="50" placeholder="Message" required></textarea>
            </div>
             <br/>
             <div className="c-submit">
            <button type="submit"  style={{backgroundColor:'rgb(34, 34, 86)',color:'white'}} value="Submit" >Send</button>
            </div>
          </form>
        </div>

    </>
  )
}

export default Achieverspuc