import React from "react";
import "./Services.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Services = () => {
  return (
    <>
      <br />
      <br />
      <br />

      <div className="service-all" style={{backgroundColor:'whitesmoke'}}>
        <div className="s-header">
          <br/>
          <h2 style={{ color:'black'}}>
            OUR COURSES
          </h2>
          <br/>
          
        </div>
        <br/>

        <div className="s-all">
          <div className="s-1" >
            <br />

           
           <h5 style={{color:'wheat',fontSize:'30px'}}>Achievers Junior </h5>
            
           
            <p style={{color:'white'}}>Achievers Junior at Achievers Tuition Classes offers exceptional tutoring for grades 1 to 6, using engaging teaching methods to build a strong foundation in core subjects.  </p>
            
           <br/>
          
            
            <a href="/Achieversjunior">
              <button className="s-button">Learn More</button>
            </a>
          </div>

          
            
          <div className="s-1">
            <br />

           
           <h5 style={{color:'wheat',fontSize:'30px'}}>Achievers Senior</h5>
            
           
            <p style={{color:'white'}}>Achievers Senior at Achievers Tuition Classes offers exceptional tutoring for grades 7 to 10, focusing on thorough subject understanding and academic excellence. </p>
            
           
            
            <br/>
            <a href="/Achieverssenior">
              <button className="s-button">Learn More</button>
            </a>
          </div>

        
         

          <div className="s-1">
            <br />

           
           <h5 style={{color:'wheat',fontSize:'30px'}}>Achievers PUC(Science & Commerce) </h5>
            
           
            <p style={{color:'white'}}>Achievers PUC (Science and Commerce) at Achievers Tuition Classes provides comprehensive tutoring for pre-university students, focusing on both science and commerce streams. </p>
            
           
            
            <a href="/Achieverspuc">
              <button className="s-button">Learn More</button>
            </a>
          </div>

          
        </div>




        <div className="s-all">
          <div className="s-1">
            <br />

           
           <h5 style={{color:'wheat',fontSize:'30px'}}>Achievers Engineering</h5>
            
           
            <p style={{color:'white'}}>Achievers Engineering at Achievers Tuition Classes provides specialized tutoring for various engineering entrance exams, including IIT JEE and other competitive exams. </p>
            
           
            <br/>
            
            <a href="/Achieversengineering">
              <button className="s-button">Learn More</button>
            </a>
          </div>

          
            
          <div className="s-1">
            <br />

           
           <h5 style={{color:'wheat',fontSize:'30px'}}>Achievers Medical </h5>
            
           
            <p style={{color:'white'}}>Achievers Medical at Achievers Tuition Classes provides specialized tutoring for medical entrance exams such as NEET.</p>
            
           <br/>
           <br/>
            
            <a href="/Achieversmedical">
              <button className="s-button">Learn More</button>
            </a>
          </div>

        
         

          <div className="s-1">
            <br />

           
           <h5 style={{color:'wheat',fontSize:'30px'}}>Achievers Commerce</h5>
            
           
            <p style={{color:'white'}}>Achievers Commerce at Achievers Tuition Classes offers specialized tutoring for commerce students, focusing on key subjects and exam preparation for academic success</p>
            
           <br/>
            
            <a href="/Achieverscommerce">
              <button className="s-button">Learn More</button>
            </a>
          </div>

          
        </div>
        <br /><br/>
      </div>
      
    </>
  );
};

export default Services;
