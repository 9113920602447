import React, { useState } from "react";
import "./Header.css";
import { HamburgetMenuClose, HamburgetMenuOpen } from "./Icons";
import Dropdown from 'react-bootstrap/Dropdown';

function Header() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const handleDropdownClick = () => setDropdown(!dropdown);

  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
          <a href="/" className="nav-logo">
            <img src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0QsTXmB6HWBvdjM5zGbRnmhbgi9YcpQY58A&s'} alt="logo"/>
          </a>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <a href="/" className="nav-links" onClick={handleClick}>
                Home
              </a>
            </li>
            <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic" style={{backgroundColor:'black',border:'none',fontSize:'19px'}}>
        Our Courses
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item  className='hover' href="/Achieversjunior" style={{padding:'10px',fontSize:'20px'}}>Achievers Junior</Dropdown.Item>
        <Dropdown.Item className='hover' href="/Achieverssenior" style={{padding:'10px',fontSize:'20px'}}>Achievers Senior</Dropdown.Item>
        <Dropdown.Item className='hover' href="/Achieversengineering" style={{padding:'10px',fontSize:'20px'}}>Achievers Engineering</Dropdown.Item>
        <Dropdown.Item  className='hover' href="/Achieversmedical" style={{padding:'10px',fontSize:'20px'}}>Achievers Medical</Dropdown.Item>
        <Dropdown.Item className='hover' href="/Achieverspuc" style={{padding:'10px',fontSize:'20px'}}>Achievers PUC</Dropdown.Item>
        <Dropdown.Item  className='hover' href="/Achieverscommerce" style={{padding:'10px',fontSize:'20px'}}>Achievers Commerce</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
            <li className="nav-item">
              <a href="/projects" className="nav-links" onClick={handleClick}>
                Gallery
              </a>
            </li>
            <li className="nav-item">
              <a href="/about" className="nav-links" onClick={handleClick}>
                About Us
              </a>
            </li>
            <li className="nav-item">
              <a href="contact" className="nav-links" onClick={handleClick}>
                Contact Us
              </a>
            </li>
            <li style={{ backgroundColor: 'navy' }} className=" nav-item">
              <a  href="tel:+917022277630" className="call nav-links" onClick={handleClick}>
                Call Us
              </a>
            </li>
            <li style={{ backgroundColor: 'navy' }} className="email nav-item">
              <a href="mailto:academyachievers29@gmail.com" className="nav-links" onClick={handleClick}>
                Email Us
              </a>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            {click ? (
              <span className="icon">
                <HamburgetMenuClose />
              </span>
            ) : (
              <span className="icon">
                <HamburgetMenuOpen />
              </span>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
