import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import './Achieversjunior.css';


const Achieverssenior = () => {
  return (
    <>

<div className='banner'>
        <h1>Achievers Senior</h1>
    </div> 


    <div className=' a-all'>
      

       <div className='a-text1'>
              <h5>Achievers Academy offers a specialized course named Achievers Senior, tailored for students in classes 7 to 10. This course focuses on strengthening the understanding of key subjects, preparing students for higher academic challenges. Our skilled educators employ a variety of teaching techniques to engage students and foster deep comprehension. The curriculum is designed to be rigorous yet supportive, encouraging analytical thinking and problem-solving skills. Personalized guidance ensures that each student receives the attention they need to excel. Regular evaluations and constructive feedback help monitor progress and identify areas for improvement. In addition to academics, Achievers Senior promotes the development of essential life skills and self-discipline. Our aim is to provide a well-rounded education that empowers students to achieve their full potential and succeed in their future academic endeavors.</h5>
              
              
       </div>
       

       <div className='a-image1'>
         <img src='https://akm-img-a-in.tosshub.com/indiatoday/images/story/202008/tea_0.jpeg?size=690:388' />
       </div>


    </div>
    
    <div className=' a-all'>
       <div className='a-image'>
         <img src='https://ssbcrackexams.com/wp-content/uploads/2024/01/coaching-classes.jpg' />
       </div>

       <div className='a-text'>
              <h1 style={{fontFamily:'serif'}}>Course Highlights</h1>
              <h4><FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''} Covers all key subjects for classes 7 to 10, ensuring a solid academic foundation.</h4>
              <h4> <FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''}  Learn from skilled teachers who use engaging and effective teaching methods.</h4>
              <h4><FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''} Weekly tests and feedback to monitor progress and identify areas for improvement.</h4>
              <h4><FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''}Individualized support to cater to each student's unique learning needs.</h4>
              <h4><FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''} Focus on critical thinking, problem-solving, and analytical skills to prepare for future academic challenges.</h4>
              
              <br/>
              
       </div>
       
    </div>
      <br/>

    <h1 style={{textAlign:'center',fontFamily:'serif'}}>Get In Touch With us</h1>
      <br/>
    <div class="form-container">
          <form action="your-google-form-link" method="post">
            <div className="c-name">
            
            <input  style={{fontSize:'20px'}} type="text" id="name" name="name" placeholder="Name"required />
            
           <div style={{padding:'15px'}}></div>
            
            <input style={{fontSize:'20px'}} type="email" id="gmail" name="gmail" placeholder="Gmail" required />
            

            </div>
            <br/>
            <br/>
             <div className="c-subject">
            
            <input style={{fontSize:'20px'}} type="text" id="subject" name="subject" placeholder="Subject"required />
            </div>

            <br/><br/>
            <div className="c-message">
            <textarea style={{fontSize:'20px'}} id="message" name="message" rows="4" cols="50" placeholder="Message" required></textarea>
            </div>
             <br/>
             <div className="c-submit">
            <button type="submit"  style={{backgroundColor:'rgb(34, 34, 86)',color:'white'}} value="Submit" >Send</button>
            </div>
          </form>
        </div>

    </>
  )
}

export default Achieverssenior