





import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import About from './about/About'

import { Choose } from './choose/Choose'
import Footer1 from './Footer1/Footer1'

import Services from './ourCourses/Services'

import Projects from './gallery/Gallery'


import img1 from '../Assets/rcscoaching1.jpeg';
import img2 from '../Assets/rcscoaching2.jpeg';



const responsiveImageHero = {
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 1
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 1
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1
    }
};

const images = [
    img1, img2
];

const Home = () => {
    return (
      <>
        <Carousel
            showDots
            infinite
            containerClass="carouselContainer"
            slidesToSlide={1}
            responsive={responsiveImageHero}
        >
            {images.slice(0, 4).map((image, id) => {
                return (
                    <img
                        key={id}
                        draggable={true}
                        src={image}
                        style={{
                            width: '100%',
                            display: 'block',
                            height: '90vh',
                            margin: '0 auto',
                            
                        }}
                        alt={'img'} />
                );
            })}
        </Carousel>



<About/>
        
<br/>

<Services/>
<Projects/>

<Choose/>


<Footer1/>



</>
    );
}

export default Home;
