import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import './Achieversjunior.css';


const Achieversjunior = () => {
  return (
    <>
     <div className='banner'>
        <h1>Achievers Junior</h1>
        
       
    </div>


    <div className=' a-all'>
      

       <div className='a-text1'>
              <h5>Achievers Academy offers a dedicated course named Achievers Junior, specifically designed for students in classes 1 to 6. This course aims to build a strong foundation in core subjects, fostering a love for learning from an early age. Our experienced educators use engaging and interactive teaching methods to ensure that young students grasp fundamental concepts effectively. Personalized attention is given to each student, catering to their unique learning needs and styles. The curriculum is crafted to be comprehensive yet enjoyable, encouraging curiosity and critical thinking. Regular assessments and feedback help track progress and identify areas for improvement. With a focus on holistic development, Achievers Junior also incorporates activities that enhance creativity and social skills. Our goal is to create a nurturing and stimulating environment where young minds can thrive and achieve academic excellence.</h5>
              
              
       </div>
       

       <div className='a-image1'>
         <img src='https://akm-img-a-in.tosshub.com/indiatoday/images/story/202008/tea_0.jpeg?size=690:388' />
       </div>


    </div>


    <div className=' a-all'>

       <div className='a-image'>
         <img src='https://ssbcrackexams.com/wp-content/uploads/2024/01/coaching-classes.jpg' />
       </div>

       <div className='a-text'>
              <h1 style={{fontFamily:'serif'}}>Course Highlights</h1>
              <h4><FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''}  Tailored curriculum for classes 1 to 6 to build a strong academic base.</h4>
              <h4> <FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''} Interactive and fun learning techniques to keep young minds interested.</h4>
              <h4><FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''} Small class sizes ensure individual attention and customized learning plans.</h4>
              <h4><FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''} Emphasis on social skills, creativity, and confidence-building activities.</h4>
              <h4><FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''} Periodic evaluations to track progress and identify areas for improvement.





</h4>
              <br/>
              
       </div>
       
    </div>
      <br/>

    <h1 style={{textAlign:'center',fontFamily:'serif'}}>Get In Touch With us</h1>
      <br/>
    <div class="form-container">
          <form action="your-google-form-link" method="post">
            <div className="c-name">
            
            <input  style={{fontSize:'20px'}} type="text" id="name" name="name" placeholder="Name"required />
            
           <div style={{padding:'15px'}}></div>
            
            <input style={{fontSize:'20px'}} type="email" id="gmail" name="gmail" placeholder="Gmail" required />
            

            </div>
            <br/>
            <br/>
             <div className="c-subject">
            
            <input style={{fontSize:'20px'}} type="text" id="subject" name="subject" placeholder="Subject"required />
            </div>

            <br/><br/>
            <div className="c-message">
            <textarea style={{fontSize:'20px'}} id="message" name="message" rows="4" cols="50" placeholder="Message" required></textarea>
            </div>
             <br/>
             <div className="c-submit">
            <button type="submit"  style={{backgroundColor:'rgb(34, 34, 86)',color:'white'}} value="Submit" >Send</button>
            </div>
            <br/>
            <br/>
          </form>
        </div>

    </>
  )
}

export default Achieversjunior