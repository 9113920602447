import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import './Achieversjunior.css';


const Achieverscommerce = () => {
  return (
    <>

<div className='banner'>
        <h1>Achievers Commerce</h1>
        
       
    </div>


    <div className=' a-all'>
      

       <div className='a-text1'>
              <h5>

Achievers Academy offers a dedicated course named Achievers Commerce, designed for B.Com students. This course focuses on providing a thorough understanding of core commerce subjects, ensuring students are well-prepared for their academic and professional careers. Our experienced faculty use practical teaching methods to simplify complex concepts and enhance comprehension. The curriculum covers essential topics in accounting, economics, business law, and finance, promoting analytical and critical thinking skills. Personalized guidance and regular assessments help track progress and address individual learning needs. Achievers Commerce also emphasizes real-world applications and problem-solving techniques, crucial for success in commerce fields. Our goal is to equip students with the knowledge and skills required to excel in their B.Com studies and future careers in commerce.</h5>
              
              
       </div>
       

       <div className='a-image1'>
         <img src='https://akm-img-a-in.tosshub.com/indiatoday/images/story/202008/tea_0.jpeg?size=690:388' />
       </div>


    </div>


    <div className=' a-all'>

       <div className='a-image'>
         <img src='https://ssbcrackexams.com/wp-content/uploads/2024/01/coaching-classes.jpg' />
       </div>

       <div className='a-text'>
              <h1 style={{fontFamily:'serif'}}>Course Highlights</h1>
              <h4><FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''} Tailored coaching designed specifically for B.Com students.</h4>
              <h4> <FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''}   In-depth coverage of accounting, economics, business law, and finance topics.</h4>
              <h4><FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''} Experienced faculty with expertise in commerce education and exam preparation.</h4>
              <h4><FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''} Regular assessments, mock exams, and feedback sessions to track progress.</h4>
              <h4><FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''} Emphasis on real-world applications and skills essential for success in commerce fields.
</h4>
              
              <br/>
              
       </div>
       
    </div>
      <br/>

    <h1 style={{textAlign:'center',fontFamily:'serif'}}>Get In Touch With us</h1>
      <br/>
    <div class="form-container">
          <form action="your-google-form-link" method="post">
            <div className="c-name">
            
            <input  style={{fontSize:'20px'}} type="text" id="name" name="name" placeholder="Name"required />
            
           <div style={{padding:'15px'}}></div>
            
            <input style={{fontSize:'20px'}} type="email" id="gmail" name="gmail" placeholder="Gmail" required />
            

            </div>
            <br/>
            <br/>
             <div className="c-subject">
            
            <input style={{fontSize:'20px'}} type="text" id="subject" name="subject" placeholder="Subject"required />
            </div>

            <br/><br/>
            <div className="c-message">
            <textarea style={{fontSize:'20px'}} id="message" name="message" rows="4" cols="50" placeholder="Message" required></textarea>
            </div>
             <br/>
             <div className="c-submit">
            <button type="submit"  style={{backgroundColor:'rgb(34, 34, 86)',color:'white'}} value="Submit" >Send</button>
            </div>
          </form>
        </div>

    </>
  )
}

export default Achieverscommerce