import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import './Achieversjunior.css';


const Achieversmedical = () => {
  return (
    <>

<div className='banner'>
        <h1>Achievers Medical</h1>
        
       
    </div>

    <div className=' a-all'>
      

       <div className='a-text1'>
              <h5>Achievers Academy offers a focused course named Achievers Medical, tailored for students preparing for medical entrance exams such as NEET. This course aims to provide a deep understanding of Biology, Chemistry, and Physics, ensuring students are well-prepared for the competitive exam environment. Our expert instructors use detailed and structured teaching methods to cover the extensive syllabus effectively. The curriculum is designed to enhance problem-solving abilities and critical thinking skills. Personalized attention and regular mock tests help students gauge their progress and identify areas that need improvement. Achievers Medical also emphasizes effective study techniques and time management skills, essential for excelling in entrance exams. Our goal is to equip students with the necessary knowledge, strategies, and confidence to achieve high scores in NEET and AIIMS, paving the way for their future medical careers.</h5>
              
              
       </div>
       

       <div className='a-image1'>
         <img src='https://akm-img-a-in.tosshub.com/indiatoday/images/story/202008/tea_0.jpeg?size=690:388' />
       </div>


    </div>


    <div className=' a-all'>

       <div className='a-image'>
         <img src='https://ssbcrackexams.com/wp-content/uploads/2024/01/coaching-classes.jpg' />
       </div>

       <div className='a-text'>
              <h1 style={{fontFamily:'serif'}}>Course Highlights</h1>
              <h4><FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''}Detailed coverage of Biology, Chemistry, and Physics for medical entrance exams.</h4>
              <h4> <FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''}  Experienced instructors with a proven track record of success in NEET and AIIMS coaching.
</h4>
              <h4><FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''}  Access to high-quality study materials and resources curated for medical aspirants.</h4>
              <h4><FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''} Regular mock tests and practice sessions to simulate exam conditions and boost confidence.</h4>
              <h4><FontAwesomeIcon icon={faCheckSquare} size="1x" /> {''}  Individual attention and counseling to address students' strengths, weaknesses, and exam strategies.</h4>
              <br/>
              
       </div>
       
    </div>
      <br/>

    <h1 style={{textAlign:'center',fontFamily:'serif'}}>Get In Touch With us</h1>
      <br/>
    <div class="form-container">
          <form action="your-google-form-link" method="post">
            <div className="c-name">
            
            <input  style={{fontSize:'20px'}} type="text" id="name" name="name" placeholder="Name"required />
            
           <div style={{padding:'15px'}}></div>
            
            <input style={{fontSize:'20px'}} type="email" id="gmail" name="gmail" placeholder="Gmail" required />
            

            </div>
            <br/>
            <br/>
             <div className="c-subject">
            
            <input style={{fontSize:'20px'}} type="text" id="subject" name="subject" placeholder="Subject"required />
            </div>

            <br/><br/>
            <div className="c-message">
            <textarea style={{fontSize:'20px'}} id="message" name="message" rows="4" cols="50" placeholder="Message" required></textarea>
            </div>
             <br/>
             <div className="c-submit">
            <button type="submit"  style={{backgroundColor:'rgb(34, 34, 86)',color:'white'}} value="Submit" >Send</button>
            </div>
          </form>
        </div>

    </>
  )
}

export default Achieversmedical